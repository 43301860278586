import '../../../service/gamification/mask-collection.js';
(function () {
  'use strict';

  const component = { name: 'lindaMaskCollections' };

  controller.$inject = ['$scope', 'maskCollection'];

  function controller($scope, _maskCollection) {
    $scope.preloader = false;
    $scope.collection = [];

    getCollection();

    $scope.grab = (id) => {
      $scope.preloader = true;

      _maskCollection
        .grab({ id })
        .then(() => getCollection())
        .catch(() => {})
        .finally(() => ($scope.preloader = false));
    };

    function getCollection() {
      $scope.preloader = true;

      _maskCollection
        .list()
        .then((a) => ($scope.collection = a.result))
        .catch(() => {})
        .finally(() => ($scope.preloader = false));
    }
  }

  app.component(component.name, {
    controller,
    template: app.getTU(component.name),
  });
})();
