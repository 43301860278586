// This direcrive scrolls info page to the child element based on provided 'elementSelector'
// on page load and opens corresponding info page section 'elementToOpen' by adding 'classToAdd' class to it

(function () {
  'use strict';

  const directive = { name: 'wazambaScrollAndOpenOnLoad' };

  controller.$inject = ['$window', '$interval'];

  function controller($window, $interval) {
    const link = (scope, element, attrs) => {
      const config = scope.$eval(attrs[directive.name]);
      let container = undefined;
      let child = undefined;
      let childHeader = undefined;

      const isContentLoaded = $interval(() => {
        container = document.querySelector('html');
        child = element[0].querySelector(config.elementSelector);
        childHeader = element[0].querySelector(config.elementToOpen);

        if (child && childHeader && container) {
          childHeader.classList.add(config.classToAdd);
          setTimeout(()=>{
            container.scrollTop = child.offsetTop + config.scrollOffset;
            $interval.cancel(isContentLoaded);
          }, 200);
        }
      }, 50);
    };

    return {
      link,
    };
  }

  app.directive(directive.name, controller);
})();
