import '../../service/domain/user.js';
import '../../service/domain/popup.js';
import '../../service/domain/promo.js';
import { filter } from 'rxjs/operators';

export const holidayPromo = 'holidayPromo';

class HolidayPromo {
  static $inject = ['promo', 'system$', 'user', '$timeout', 'popup'];
  constructor(promo, system$, user, $timeout, popup) {
    this.promo = promo;
    this.system$ = system$;
    this.user = user;
    this.$timeout = $timeout;
    this.popup = popup;
  }

  promoRequest = null;
  promos = [];

  init() {
    const config = {category: 'season_promo'}
    this.getPromoList(config).then(() => {
      this.initWelcomePopup();
    });
  }

  convertToIsoString(date) {
    if (!date) return;
    const yourDate = new Date(date);
    return yourDate.toISOString().split('T')[0];
  }

  triggerPopup(previousLogin, currentDate) {
    if (previousLogin === currentDate) {
      return;
    }

    this.$timeout(() => {
      this.popup.open({ name: 'holiday-welcome', content: this.promos });
    }, 1000);
  }

  getPromoList(params) {
    if (this.promoRequest) {
      return this.promoRequest;
    }

    this.promoRequest = this.promo.list(params).then((data) => {
        if (!data.result.length) {
          return this.promos;
        }
        this.promos = [...data.result];

        return this.promos;
    });

    return this.promoRequest;
  }

  initWelcomePopup() {
    this.system$
      .pipe(filter((message) => message.action === 'login'))
      .subscribe(() => {
        const currentDate = +new Date();
        const previousLoginDate = this.user.profile.sessions_info.previous_ts * 1000 || null;
        if (this.promos.length) {
          this.triggerPopup(this.convertToIsoString(previousLoginDate), this.convertToIsoString(currentDate));
        }
      });
  }
}

app.factory(holidayPromo, HolidayPromo);
