import analytics from "../../app/service/analytics/analytics.js";

(function () {

    'use strict';

    // this directive need to add param "sport: true" to sport routs

    const directive = { name: 'wazambaBurgerAnalytics' };

    controller.$inject = ['$state', 'analytics'];
    function controller($state, analytics){

        function link( scope, element ){
            element.on('click', () => {
                const bodyClass = 'linda-hide-sidebar';
                analytics.send('Burger', document.body.className.indexOf(bodyClass) > -1 ? 'Burger' : 'Minimize_Menu' , 'Click_Button', {nonInteraction: true}  )
            })

        }

        return {
            restrict: 'A',
            link
        };
    }

    app.directive( directive.name, controller );

})();
