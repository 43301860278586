(function(){

    'use strict';

    controller.$inject = [ '$state' ];

    function controller( $state ){

        function link( scope, element, attrs ){

            const variable = attrs.getUrlToScope || 'stateParams';

            scope[variable] = window.location.pathname.substring(4);
            if (scope[variable] == ''){scope[variable] = 'main-page'}
            scope.$on( '$locationChangeSuccess', function( event, toState, toParams, fromState, fromParams ){
                scope[variable] = window.location.pathname.substring(4);
                if (scope[variable] == ''){scope[variable] = 'main-page'}
            } );
            

        }

        return {
            restrict: 'A',
            link: link
        };

    }

    app.directive( 'getUrlToScope', controller );

})();

