//- All property styles you can check on this page:
//- https://sb2widgetsstatic-altenar2-stage.biahosted.com/docs/?path=/docs/widgets-tokens--page
//- On this page you can try constructor styles:
//- https://sb2widgetsstatic-altenar2-stage.biahosted.com/docs/?path=/story/widgets-wtabbedeventsbytype--live-now
let WHighlights = {
  "OddBox": {
    "borderWidth":1,
    "borderStyle": "solid",
    "borderRadius": 20,
    "borderColor": "rgba(226, 191, 139, 0.6)",
    "background": "#FFFFFF",
    "paddingVertical": 10,
    "paddingHorizontal": 16,
    "oddFont": [
      "Montserrat",
      14,
      "normal",
      600,
      "normal"
    ],
    "labelFont": [
      "Montserrat",
      14,
      "normal",
      400,
      "normal"
    ],
    "oddColor": "#071F3E",
    "labelColor": "rgba(189,115,115,0.54)",
    "arrowUp": [
      24,
      24,
      "#4CAF50"
    ],
    "arrowDown": [
      24,
      24,
      "#1f3817"
    ]
  },
  "OddBoxSelected": {
    "borderColor": "rgba(41, 129, 11, 0.23)",
    "background": "#3ED70B",
    "oddColor": "#FFFFFF",
    "labelColor": "#FFFFFF"
  },
  "MarketBox": {
    "width": "100%",
    "spacing": 8
  },
  "EventBox": {
    "borderWidth": 1,
    "borderColor": "rgba(7, 31, 62, 0.1)",
    "borderStyle": "solid",
    "borderRadius": 8,
    "background": "#FFDEAD",
    "paddingVertical": 12,
    "paddingHorizontal": 12,
    "borderBottom": "0",
    "liveTimeFont": [
      "Montserrat",
      14,
      "normal",
      500,
      "21px"
    ],
    "dateTimeFont": [
      "Montserrat",
      12,
      "normal",
      600,
      "20px"
    ],
    "categoryChampionshipFont": [
      "Montserrat",
      12,
      "normal",
      600,
      "20px"
    ],
    "competitorFont": [
      "Montserrat",
      14,
      "normal",
      700,
      "21px"
    ],
    "scoreFont": [
      "Montserrat",
      14,
      "normal",
      700,
      "21px"
    ],
    "liveTimeColor": "#65697B",
    "dateTimeColor": "rgba(0, 0, 0, 0.87)",
    "categoryChampionshipColor": "rgba(0, 0, 0, 0.54)",
    "competitorColor": "rgba(0, 0, 0, 0.87)",
    "scoreColor": "rgba(0, 0, 0, 0.87)"
  },
  "HighlightsTab": {
    "borderWidth": 0,
    "borderColor": "transparent",
    "borderStyle": "none",
    "font": [
      "Montserrat",
      16,
      "normal",
      700,
      "14px"
    ],
    "background": "transparent",
    "color": "#071F3E",
    "paddingHorizontal": 12,
    "paddingVertical": 10,
    "borderRadius": 8,
    "spacing": 16,
    "textTransform": "capitalize",
    "borderBottom": "0",
  },
  "HighlightsTabSelected": {
    "font": [
      "Montserrat",
      14,
      "normal",
      700,
      "14px"
    ],
    "borderColor": "#6A4326",
    "color": "#FFFFFF",
    "background": "#6A4326",
    "borderRadius": 8,
    "textTransform": "capitalize",

    "indicatorColor": "transparent",
  },
  "Highlights": {
    "borderWidth": 0,
    "borderRadius": 8,
    "borderColor": "unset",
    "paddingHorizontal": 16,
    "paddingVertical": 16,
    "background": "#FFEDD2",
    "spacing": 0,
  },
  "HighlightsHeader": {
    "color": "#071F3E",
    "font": [
      "Montserrat",
      18,
      "normal",
      "800",
      "20px"
    ],
    "textTransform": "capitalize",
    "background": "transparent",
    "spacing": 8,
  },
  "EventList": {
    "rowSpacing": 8,
    "columnSpacing": 16,
  },
}
let carousel = {
  "OddBox": {
    "borderWidth": 4,
    "borderColor": "#FFAD61",
    "borderRadius": 50,
    "variant": 0,
    "spacing": 4,
    "background": "#FF7800",
    "paddingVertical": 8,
    "paddingHorizontal": 16,
    "oddFont": [
      "Montserrat",
      14,
      "normal",
      700,
      "normal"
    ],
    "labelFont": [
      "Montserrat",
      14,
      "normal",
      400,
      "normal"
    ],
    "oddColor": "#FFF",
    "labelColor": "#FFF",
    "arrowUp": [
      24,
      24,
      "#4CAF50"
    ],
    "arrowDown": [
      24,
      24,
      "rgba(244,67,54,0.52)"
    ]
  },
  "OddBoxSelected": {
    "borderColor": "#FFB775",
    "background": "linear-gradient(180deg, #FF7800 0%, #FF4D00 100%)",
    "oddColor": "#FFF",
    "labelColor": "#FFF"
  },
  "BannerEventBox": {
    "variant": 0,
    "width": 320,
    "borderColor": "transparent",
    "background": "center / cover no-repeat url(\"https://wazamba.com/joxi/wazamba/renovation/main/wood-mask-stone%403x.png\")",
    "paddingVertical": 20,
    "paddingHorizontal": 20,
    "timeFont": [
      "Montserrat",
      16,
      "normal",
      700,
      "18px"
    ],
    "dateFont": [
      "Montserrat",
      16,
      "normal",
      700,
      "218x"
    ],
    "championshipFont": [
      "Montserrat",
      14,
      "normal",
      700,
      "14px"
    ],
    "competitorFont": [
      "Montserrat",
      16,
      "normal",
      500,
      "24px"
    ],
    "messageBodyFont": [
      "Montserrat",
      16,
      "normal",
      500,
      "24px"
    ],
    "messageHeaderFont": [
      "Montserrat",
      16,
      "normal",
      500,
      "24px"
    ],
    "timeColor": "#FFF",
    "dateColor": "#FFF",
    "championshipColor": "rgba(255, 255, 255, 0.6)",
    "competitorColor": "#FFF",
    "messageHeaderColor": "#fd0710",
    "messageBodyColor": "rgba(108,30,175,0.87)",
    "dividerColor": "rgb(99,246,13)",
    "dividerWidth": 1,
    "dividerHeight": "85%"
  },
  "MarketBox": {
    "font": [
      "Montserrat",
      12,
      "normal",
      500,
      "21px"
    ],
    "color": "rgba(0, 0, 0, 0.54)",
    "spacing": 8
  },
  "CompetitorLogo": {
    "height": 64,
    "width": 64,
    "background": "#fff",
    "borderRadius": "50%",
    "borderColor": "#E9EBF7",
    "offset": -8
  },
  "EventsCarousel": {
    "borderWidth": 0,
    "borderColor": "unset",
    "borderRadius": 0,
    "width": "unset",
    "paddingHorizontal": 0,
    "paddingVertical": 24,
    "spacing": 12
  },
  "EventsCarouselControls": {
    "borderWidth": 1,
    "borderColor": "transparent",
    "borderRadius": 0,
    "dotBackground": "transparent",
    "selectedDotBackground": "transparent",
    "background": "0 center / 61px no-repeat url(\"https://wazamba.com/joxi/wazamba/renovation/main/events-arrow.png\")",
    "color": "#FFF",
    "height": 224,
    "width": 32
  },
  "EventsCarouselControlsHovered": {
    "background": "100% center / 61px no-repeat url(\"https://wazamba.com/joxi/wazamba/renovation/main/events-arrow.png\")",
    "color": "#FFF",
  },

  //-sport top events
  "BannerEventBoxZeroMargin": {
    "paddingHorizontal": 8,
    "paddingVertical": 4,
    "font": [
      "Roboto",
      18,
      "normal",
      700,
      1.2
    ]
  },
  "ZeroMargin": {
    "borderWidth": 0,
    "borderColor": "unset",
    "borderRadius": 50,
    "color": "rgba(0, 0, 0, 0.87)",
    "background": "#FFCC00"
  },
};
const $_ALTENARCONFIG = {
  widget: {
    tokens: {
      WHighlights
    },
    carousel
  }
}

export { $_ALTENARCONFIG };
