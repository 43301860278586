(function () {
  'use strict';
  const directive = {name: 'wazambaRegistrationPromocode'};
  function controller() {
    function link($scope, element){
      $scope.promocodeLabelFlag = false;
      $scope.promocodeActive = false;
      $scope.withoutBonusFlag = false;
      $scope.bonusChoosed = true;

      $scope.setStatusPromocode = (status = false, type, event) => {
        $scope.promocodeLabelFlag = status;

        switch (type) {
          case 'bonus':
            $scope.formData.code = '';
            $scope.promocodeActive = false;
            $scope.bonusChoosed = true;
            break;

          case 'without':
            $scope.formData.code = '';
            $scope.activePromotion = '';
            $scope.formData.enable_trigger_codes = true;
            $scope.formData.trigger_codes = 'no_bonus';
            $scope.promocodeActive = false;
            $scope.withoutBonusFlag = true;
            $scope.bonusChoosed = true;
            break;

          case 'promocode':
            $scope.promocodeActive = true;
            $scope.bonusChoosed = true;
            break;

          case 'reset':
            if(event.target.className.includes("is-apply-promo")){
              return;
            }
            $scope.activePromotion = '';
            $scope.formData.enable_trigger_codes = false;
            $scope.withoutBonusFlag = false;
            $scope.bonusChoosed = false;
            break;

          default:
            break;
        }
      }
    }

    return {
      restrict: 'A',
      link
    };
  }

  app.directive(directive.name, controller);
})();
