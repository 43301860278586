//- This direcrive scrolls active tab to the center of the screen on element initializing (based on initSelector)
//- Additionally it can work by calling scrollToTab function and passing tab's class into it
//- optionally you can set:
//- - speed: smooth scroll step duration (default is 5ms)
//- - step: amount of pixels to scroll at a time during smooth scroll (default is 5px)
//- - sidemenu: width of sidemenu that should be taken into account (default is 0)
//- To scroll to the parent element center please define `scrollContainer`

(function () {
  'use strict';

  const directive = { name: 'wazambaSmoothScrollOnClick' };

  controller.$inject = ['$window', '$interval'];

  function controller($window, $interval) {
    const link = (scope, element, attrs) => {
      const config = scope.$eval(attrs[directive.name]);
      const SCROLL_STEP = config.step ? config.step : 5;
      const SPEED = config.speed ? config.speed : 5;
      const container = element[0];
      //- If active element should be scrolled to the container center rather the to
      //- the screen center `scrollContainer` should be equal to the parent element that should be scrolled
      const scrollContainer = config.scrollContainer ? container.querySelector(config.scrollContainer) : undefined;
      const sidemenuWidth = config.sidemenu === undefined ? 0 : config.sidemenu;

      scope.scrollToTab = function (selector) {
        setTimeout(function () {
          const child = selector ? container.querySelector(selector) : container.querySelector(config.initSelector);
          if (!child) return;
          const isSideMenuVisible = sidemenuWidth > 0 && !document.querySelector("body.linda-hide-sidebar");

          let parentWidth = 0;
          let scrollDistance = 0;
          let elementToScroll = undefined;

          //- Calculate scroll distance if scroll should be relative to the parent element
          if(scrollContainer) {
            parentWidth = scrollContainer.getBoundingClientRect().width;
            scrollDistance = (child.offsetLeft + (child.offsetWidth/2)) - (scrollContainer.scrollLeft + (parentWidth / 2));
            elementToScroll = scrollContainer;
          }
          //- Calculate scroll distance if scroll should be relative to the window width
          else {
            parentWidth = isSideMenuVisible ? ($window.innerWidth - sidemenuWidth) : $window.innerWidth;
            scrollDistance = child.getBoundingClientRect().left - (parentWidth / 2) + (child.offsetWidth / 2);
            elementToScroll = container;
          }

          let scrollStepsNumber = Math.abs(Math.floor(scrollDistance / SCROLL_STEP));
          if (!scrollStepsNumber) return;

          let previousScrollPosition;

          const slideTimer = $interval(() => {
            if (scrollStepsNumber > 0) {
              if (scrollDistance > 0) {
                elementToScroll.scrollLeft += SCROLL_STEP;
              } else {
                elementToScroll.scrollLeft -= SCROLL_STEP;
              }
              scrollStepsNumber--;
              if (elementToScroll.scrollLeft === previousScrollPosition) {
                scrollStepsNumber = 0;
              }
              previousScrollPosition = elementToScroll.scrollLeft;
            } else {
              $interval.cancel(slideTimer);
            }
          }, SPEED);
        }, 200);
      };

      scope.scrollToTab(config.initSelector);
    };

    return {
      restrict: 'A',
      link,
    };
  }

  app.directive(directive.name, controller);
})();
