import '../../service/domain/remembered.js';
import '../../service/registration/registration-deposit.js';

(function () {
  'use strict';

  const component = {name: 'registrationDepositPopup'};

  controller.$inject = ['$scope', 'registrationDeposit', 'remembered'];

  function controller($scope, registrationDeposit, _remembered) {
    $scope.hasRememberedUser = !!_remembered.login;
    $scope.depositInfo = registrationDeposit.store.getFormData();
    $scope.setStep = (step)=> {
      registrationDeposit.setCurrentStep(step)
    }
    const subscription = registrationDeposit.stepUpdate$.subscribe(answer => {
      $scope.currentStep = answer;
    });

    this.$onDestroy = ()=> {
      subscription.unsubscribe();
      registrationDeposit.resetSteps();
    }
  }

  app.component(component.name, {
    controller,
    template: app.getTU(component.name),
  });
})();
