import user$ from '../../service/rx/user$.js';
import tournament from '../../service/domain/tournament.js';

(function () {
  'use strict';

  const component = { name: 'gamePageSidebarTournaments' };

  controller.$inject = ['$scope', '$q', '$state', 'tournament', 'user$'];

  function controller($scope, $q, $state, _tournament, _user$) {
    $scope.game = {};
    $scope.isGameInTournaments = false;
    $scope.preloader = false;
    $scope.tournaments = [];

    this.$onChanges = function () {
      if (this.game) {
        $scope.game = this.game;
      }
    };

    function getTournaments() {
      $scope.preloader = true;
      _tournament
        .list({ showPlayersInfo: true })
        .then(({ result }) => {
          $scope.tournaments = result.filter((item) => {
            return !item.sport && item.status === 1;
          });
          sortTournaments();
        })
        .catch(() => {})
        .finally(() => {
          $scope.preloader = false;
        });
    }

    function sortTournaments() {
      if ($scope.game?.tournaments) {
        $scope.tournaments.map((tournamentItem) => {
          tournamentItem._hasCurrentGame = $scope.game.tournaments.some((gameTournament) => {
            return tournamentItem.name.includes(gameTournament.name);
          });
        });
      } else {
        tournamentItem._hasCurrentGame = false;
      }

      $scope.tournaments.sort((prev, next) => {
        if (prev._hasCurrentGame !== next._hasCurrentGame) {
          return +next._hasCurrentGame - prev._hasCurrentGame;
        }
        return prev.counter - next.counter;
      });

      $scope.isGameInTournaments = $scope.tournaments[0]._hasCurrentGame;
    }

    getTournaments();

    const subscription = _user$.subscribe((user) => {
      getTournaments();
    });

    $scope.$on('$destroy', function () {
      subscription.unsubscribe();
    });
  }

  app.component(component.name, {
    controller,
    template: app.getTU(component.name),
    bindings: {
      game: '<',
    },
  });
})();
