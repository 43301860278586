import user from "../../app/service/domain/user.js";
import league from "../../app/service/domain/league.js";
import popup from "../../app/service/domain/popup.js";

(function(){
    'use strict';

    const directive = { name: 'leaguePopup' };

    controller.$inject = [ 'user', 'popup', 'league','$timeout' ];

    function controller( _user, _popup, _league, $timeout ){

        function link( scope, element, attrs ){

            $timeout( () => {

                let newLeague = 9 - scope.pc.leagues.new;
                let currentLeague = 9 - scope.pc.leagues.old;
                let oldPoints = scope.pc.oldPoints;
                let newPoints = scope.pc.newPoints;
                let diffPoints = newPoints - oldPoints;

                let points = (new Array(newLeague - currentLeague + 1).fill(0)).map((val, i, arr) => {
                    return Math.floor((diffPoints / arr.length) * (i + 1) + oldPoints)
                });

                const prizeAmounts = Object.values(scope.leagueInfo.leaderBoard.prizes).map((prize) => prize.instant?.amount ?? 0).reverse()

                scope.prizeAmount = prizeAmounts.slice(0, newLeague).reduce((agg, item) => {return agg + item}, 0)

                let icon = document.getElementById('weekly-icon');
                let shining = document.getElementById('shining');
                let weekly = document.getElementById('weekly');
                let weeklyPoints = document.getElementById('weekly-points');
                let weeklyPrize = document.getElementById('weekly-prize');
                let weeklyButton = document.getElementById('weekly-button');

                icon.children[0].classList.add("has-icon-league-"+currentLeague);

                function animateValue(id, start, end, duration) {
                    if (start === end) return;
                    let range = end - start;
                    let current = start;
                    let increment = end > start? 1 : -1;
                    increment = Math.floor(range / 100);
                    let stepTime = Math.abs(Math.floor(duration / range));
                    stepTime = 10;
                    let obj = document.getElementById(id);
                    let timer = setInterval(function() {
                        current += increment;
                        obj.innerHTML = current;
                        if (current >= end) {
                            obj.innerHTML = end;
                            clearInterval(timer);
                        }
                    }, stepTime);
                }

                let tl = new TimelineMax( {
                    repeat: 0,
                    paused: false
                } );



                function changeLeague(i){
                    animateValue("weekly-value", points[points.length - 1 - newLeague + i], points[points.length - newLeague + i], 1000);

                    if (i + 1 < newLeague){
                        tl.to(shining, 0.5, {scale:1, delay:0.6})
                        .to(shining, 1.5, {scale:1})
                        .to(shining, 0.5, {scale:0});
                    } else {
                        tl.to(shining, 0.5, {scale:1, delay:0.6})
                        .to(shining, 1.5, {scale:1});
                    }

                    TweenMax.to(icon, 0.5, {scale:0.6, delay:1, onComplete: function(){
                        icon.children[0].classList.remove("has-icon-league-"+i);
                        icon.children[0].classList.add("has-icon-league-"+(i + 1));

                        TweenMax.to(icon, 0.5, {scale:0.8, onComplete: function(){
                            TweenMax.to(icon, 0.2, {scale:0.7, onComplete: function(){
                                if (i + 1 < newLeague){
                                    setTimeout(changeLeague(i+1),500);
                                } else {
                                    setTimeout(function(){
                                        TweenMax.to(icon, 0.5, {
                                            scale:1,
                                            y:-215,
                                            onComplete: function(){
                                                TweenMax.to(icon.parentElement, 0.5, {height:0});
                                            }
                                        });
                                        TweenMax.to(shining, 0.5, {scale:0.7, y:-35});
                                        TweenMax.to(weeklyPoints, 0.5, {scale:0, onComplete: function(){
                                            TweenMax.to(weeklyPoints, 0.5, {height:0, delay: 0});
                                        }});

                                        TweenMax.to(weeklyPrize, 0.5, {scale:1, delay: 0.5});
                                        TweenMax.to(weeklyButton, 0.5, {scale:1, height:'auto', delay: 0.5});
                                    },1000);
                                }
                            }});
                        }});
                    }});
                }




                // show popup
                TweenMax.fromTo(weekly, .9, {scale:0},{scale:1});

                TweenMax.fromTo(icon, 0.3, {scale:0},{scale:1.2, delay: 0.5, onComplete: function(){
                    TweenMax.to(icon, 0.2, {scale:1, onComplete: function(){
                        if (newLeague > 1)  {
                            changeLeague(currentLeague);
                        } else {
                            TweenMax.to(icon, 0.5, {scale:0.7, y:-35});
                            TweenMax.to(shining, 0.5, {scale:0.7, y:-35});
                            TweenMax.to(weeklyPoints, 0.5, {scale:0});
                            TweenMax.to(weeklyPrize, 0.5, {height: "auto",scale:1, delay: 0.5});
                            TweenMax.to(weeklyButton, 0.5, {scale:1, delay: 0.5});
                        }
                    }});
                }});
            }, 0);

        }
        return {
            restrict: 'A',
            link
        };
    }
    app.directive( directive.name, controller );
})();
