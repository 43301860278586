import ScrollMagic from "scrollmagic";

(function () {

    'use strict';

    const directive = { name: 'authorizationBtn' };

    controller.$inject = [ ];
    function controller( ){

        function link( scope, element, attrs ){

                    let controller = new ScrollMagic.Controller( {} );
                    let scene = new ScrollMagic.Scene( {
                        triggerElement: element,
                    })
                        .setClassToggle(".sidebar", "show-btn")
                        .addTo( controller );


            scope.$on( '$stateChangeStart', () => {
                controller = controller.destroy(true);
                scene = scene.destroy(true);
            })
        }

        return {
            restrict: 'A',
            link
        };
    }

    app.directive( directive.name, controller );

})();
