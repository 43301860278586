const $_SLIDER = {};

$_SLIDER.registration = {
  centeredSlides: true,
  slidesPerView: 'auto',
  allowTouchMove: false,
  //observer: true,
  navigation: {
    nextEl: '.reg-slider__arrow_next',
    prevEl: '.reg-slider__arrow_prev',
    disabledClass: 'reg-slider__arrow_disabled'
  },
};

export { $_SLIDER };