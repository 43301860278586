import '../../service/domain/system.js';
import '../../service/domain/promo.js';
import '../../service/registration/registration-deposit.js';

(function () {
  'use strict';

  const component = {
    name: 'registrationDepositBonus',
  };

  controller.$inject = ['$scope', 'registrationDeposit', 'system', 'promo'];

  function controller($scope, registrationDeposit, _system, _promo) {
    $scope.preloader = false;
    $scope.promocodeFlow = 'standardFlow';
    $scope.formData = {};
    const vm = this;

    const checkOnSelectedBonus = (defaultBonus) => {
      const savedBonus = registrationDeposit.store.getFormData()?.bonus;
      if (!savedBonus) {
        $scope.choosePromotion({ trigger_codes: defaultBonus });
        return defaultBonus;
      }
      if (savedBonus.code) {
        $scope.formData = savedBonus;
        $scope.setPromoFlow('codeSelected');
      }
      return Object.values(savedBonus)[0];
    };

    vm.$onInit = async () => {
      $scope.promotions = await registrationDeposit.getPromos();
      $scope.activePromotionAlias = checkOnSelectedBonus($scope.promotions[0].trigger_codes);
    };
    $scope.setPromoFlow = (flow) => {
      $scope.promocodeFlow = flow;
    };
    $scope.choosePromotion = (selectedBonus) => {
      $scope.activePromotionAlias = Object.values(selectedBonus)[0];
      registrationDeposit.setBonus(selectedBonus);
      if (!selectedBonus.code) {
        $scope.formData.code = '';
        $scope.setPromoFlow('standardFlow');
      }
    };
  }

  app.component(component.name, {
    controller,
    template: app.getTU(component.name),
  });
})();
