import gamePageShop from '../../service/game-page-shop.js';

(function () {
  'use strict';

  const component = { name: 'gamePageSidebarShop' };

  controller.$inject = ['$scope', 'gamePageShop'];

  function controller($scope, _gamePageShop) {
    $scope.activeTab = '';

    const activeTabSubscription = _gamePageShop.tabOpened$.subscribe((tabName) => {
      $scope.activeTab = tabName;
    });

    $scope.openTab = (tabName) => {
      _gamePageShop.setActiveTab(tabName);
    };

    $scope.resetTab = () => {
      _gamePageShop.resetActiveTab();
    };

    this.$onDestroy = () => {
      activeTabSubscription?.unsubscribe();
    };
  }

  app.component(component.name, {
    controller,
    template: app.getTU(component.name),
    bindings: {},
  });
})();
