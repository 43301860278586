import '../../../service/domain/content-buffer.js';
(function () {
  'use strict';

  const component = {
    name: 'lindaShopDepositPopup',
  };

  controller.$inject = ['$scope', 'contentBuffer'];

  function controller($scope, _contentBuffer) {
    $scope.item = _contentBuffer.read('popup');
  }

  app.component(component.name, {
    controller,
    template: app.getTU(component.name),
  });
})();
