(function () {
  'use strict';

  app.filter('changeCurrencyIconPosition', function () {
    return function (currency) {
      if (['HUF', 'NOK', 'PLN', 'CZK', 'THB', 'VND', 'SEK', 'DKK', 'TND', 'KZT', 'AZN'].includes(currency)) {
        return 'true';
      }

      return 'false';
    };
  });
})();
