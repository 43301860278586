import $ from "jquery"
import user from "../../app/service/domain/user.js";

(function(){
    'use strict';


    const directive = { name: 'slickGameSlider' };

    controller.$inject = [ 'user' ];

    function controller( _user ){


        function link( scope, element, attrs ){

            function rafAsync() {
                return new Promise(resolve => {
                    requestAnimationFrame(resolve);
                });
            }

            function checkElement(selector) {
                if (element[0].querySelector(selector) === null) {
                    return rafAsync().then(() => checkElement(selector));
                } else {
                    return Promise.resolve(true);
                }
            }

            let slicks = [];

            Promise.all([
                checkElement("linda-slick")
            ]).then(() => {
                [].forEach.call(element[0].children, function(el) {
                    if (el.classList.contains('ng-scope')){
                        slicks.push(el);
                    }
                });

                let next = element[0].querySelector('.next');
                let prev = element[0].querySelector('.prev');
                prev.classList.add('disabled');

                for(let i = 0; i < slicks.length;i++){
                    $(slicks[i]).on('beforeChange', function(event, slick, currentSlide, nextSlide){
                        if(nextSlide == 0){
                            prev.classList.add('disabled')
                        } else {
                            prev.classList.remove('disabled')
                        }
                    });
                    $(slicks[i]).on('afterChange', function(event,slick, currentSlide){
                        if((slick.slideCount - currentSlide)/slick.options.slidesToScroll < 2){
                            next.classList.add('disabled')
                        } else {
                            next.classList.remove('disabled')
                        }
                    });
                }

                next.addEventListener("click", function() {
                    for(let i = 0; i < slicks.length;i++){
                        $(slicks[i]).slick('slickNext');
                    }
                });

                prev.addEventListener("click", function() {
                    for(let i = 0; i < slicks.length;i++){
                        $(slicks[i]).slick('slickPrev');
                    }
                });

            })

        }
        return {
            restrict: 'A',
            link
        };
    }
    app.directive( directive.name, controller );
})();
