(function () {
  'use strict';

  const component = { name: 'gamePageSideMenu' };

  controller.$inject = ['$scope', '$document'];

  function controller($scope, $document) {
    $scope.game = {};
    $scope.activeTab = '';

    this.$onChanges = function () {
      $scope.game = this.game;
      $scope.activeTab = this.activeTab || 'tournaments';
    };

    $scope.openTab = (tabName) => {
      $scope.activeTab = tabName;
    };
  }

  app.component(component.name, {
    controller,
    template: app.getTU(component.name),
    bindings: {
      game: '<',
      activeTab: '<',
    },
  });
})();
