import './common-registration.js';
import '../domain/paysystem.js';
import '../domain/popup.js';
import '../domain/paymentWindow.js';

export const registrationDeposit = 'registrationDeposit';

const paysystemStoreKey = "paysystem";

class RegistrationDeposit {
  static $inject = ['$window', 'commonRegistration', 'paysystem', 'popup', 'paymentWindow'];

  constructor($window, commonRegistration, paysystem, popup, paymentWindow) {
    this.popup = popup;
    this.paysystem = paysystem;
    this.paymentWindow = paymentWindow;
    this.commonRegistration = commonRegistration;

    this.step = this.commonRegistration.step;
    this.store = this.commonRegistration.store;
    this.stepUpdate$ = this.commonRegistration.stepUpdate$;
  }

  getPromos() {
    return this.commonRegistration.getPromos();
  }

  getAvatars() {
    return this.commonRegistration.getAvatars();
  }

  getCountries() {
    return this.commonRegistration.getCountries();
  }

  setBonus(data) {
    this.commonRegistration.setBonus(data)
  }

  setFormData(data) {
    this.commonRegistration.setFormData(data);
  }

  setCurrentStep(step) {
    this.commonRegistration.setCurrentStep(step);
  };

  resetSteps() {
    this.commonRegistration.resetSteps();
  }

  openGdprModalIfNeeded(isGdprNeeded) {
    return this.commonRegistration.openGdprModalIfNeeded(isGdprNeeded);
  }

  getBankingList(config) {
    return this.paysystem.banking(config)
      .then(({result}) => {
        this.store.setData({[paysystemStoreKey]: result});
        return this.store.getData(paysystemStoreKey);
      })
  }

  submitForm(formData) {
    const config = Object.assign(formData, this.prepareFormDataForSubmit());
    const depositWindow = this.paymentWindow.prepared();

    return this.commonRegistration.submit(config)
      .then(() => {
        this.checkDepositScenario(config, depositWindow);
      }).catch((e) => {
        depositWindow.close();
        throw new Error(e);
      })
  }

  prepareFormDataForSubmit() {
    const formData = this.store.getFormData();
    let {bonus, avatar} = formData;
    return {...bonus, avatar};
  };

  checkDepositScenario(formData, depositWindow) {
    const savedFormData = this.store.getFormData();

    if (savedFormData.country !== formData.country ||
      savedFormData.currency !== formData.currency) {
      this.popup.open({name: 'cashbox', content: {warning: true}});
      depositWindow.close();
      return;
    }
    if (savedFormData.paysystem.max_amount < savedFormData.amount ||
      savedFormData.paysystem.min_amount > savedFormData.amount ||
      savedFormData.paysystem.fields.length > 0) {
      this.popup.open({
        name: 'cashbox', content: {
          'tab': 'deposit',
          'step': 'form',
          'paySystem': savedFormData.paysystem,
          'amount': savedFormData.amount
        }
      });
      depositWindow.close();
      return;
    }
    this.paymentWindow.open(formData.login, savedFormData, depositWindow);
    this.popup.close();
  }
}

app.service(registrationDeposit, RegistrationDeposit);
