import '../../service/domain/account.js';
import '../../service/domain/user.js';
import '../../service/domain/popup.js';
(function () {
  'use strict';

  const component = { name: 'lindaChangeMaskPopup' };

  controller.$inject = ['$scope', 'account', 'user', 'popup'];

  function controller($scope, _account, _user, _popup) {
    const user = _user.profile.features;

    $scope.preloader = false;
    $scope.collection = [{ current: true, mask: user.mask }];

    if (user.allowed_mask) {
      user.allowed_mask.forEach((mask) => {
        $scope.collection.push({ current: false, mask });
      });
    }

    $scope.selected = $scope.collection[0];

    $scope.change = function () {
      const o = { mask: $scope.selected.mask };

      $scope.preloader = true;
      _account
        .changeMask(o)
        .then(() => {
          _popup.close();
        })
        .catch((e) => {
          _popup.close();
        })
        .finally(() => {
          _popup.close();
        });
    };
  }

  app.component(component.name, {
    controller,
    template: app.getTU(component.name),
  });
})();
