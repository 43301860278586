import '../../service/pgw.js';
import '../../service/configs/config.js';
import '../../service/rx/system$.js';
import { filter, takeUntil } from 'rxjs/operators';
import { Subject, combineLatest } from 'rxjs';
import '../../service/domain/user.js';

const componentName = 'pgwPaymentList';

class Payments {
  static $inject = ['pgw', 'config', '$scope', 'system$', '$window', '$log', 'user'];

  constructor(pgw, config, $scope, system$, $window, $log, user) {

    const destroy$ = new Subject();
    const onChangesData$ = new Subject();

    const getPayments = () => {
      const config  = {
        country: this.country,
        currency: this.currency,
        type: this.type
      }
      $scope.preloader = true;
      pgw
        .getPaymentMethods(config)
        .then((answer) => {
          $scope.collection = answer.data;
          $scope.currency = config.currency;
        })
        .catch((error) => {
          $log.warn(error);
        })
        .finally(() => {
          $scope.preloader = false;
        });
    };

    const widgetPgwLoaded$ =
      pgw.pgwInitialization$
        .pipe(
          filter(data => data),
          takeUntil(destroy$)
        )

    combineLatest(onChangesData$, widgetPgwLoaded$)
      .pipe(takeUntil(destroy$))
      .subscribe(() => {
        getPayments();
      });

    this.$onChanges = () => {
      $scope.type = this.type;
      $scope.country = this.country;
      if(this.country && this.currency) {
        onChangesData$.next();
        if(!user.status) {
          getPayments();
        }
      }
    };

    this.$onDestroy = () => {
      destroy$.next();
      onChangesData$.unsubscribe();
      destroy$.unsubscribe();
    };
  }
}

app.component(componentName, {
  controller: Payments,
  bindings: {
    type: '<',
    currency: '<',
    country: '<',
    category: '<',
  },
  template: app.getTU(componentName),
});
