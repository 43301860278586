import tournament from '../../app/service/domain/tournament.js';

(function () {
  'use strict';

  const directive = { name: 'getLeagueStatusToScope' };

  controller.$inject = ['tournament'];
  function controller(_tournament) {
    function link(scope, element, attrs) {
      scope.isLeagueStatus = null;
      _tournament.fastTournamentList({ strategy: 'league', limit: 100 }).then((data) => {
        scope.isLeagueStatus = !!data.result.length;
      });
    }
    return {
      restrict: 'A',
      link,
    };
  }

  app.directive(directive.name, controller);
})();
