import '../../../service/domain/shop.js'
import '../../../service/rx/shop$.js'
import '../../../service/rx/system$.js'
import {filter} from 'rxjs/operators';
(function () {
  controller.$inject = ['$scope', 'shop', 'shop$', 'system$'];

  function controller($scope, _shop, _shop$, _system$) {
    $scope.collection = [];
    $scope.preloader = false;

    let authorizationSubscription;
    const options = {};

    function getCollection(o) {
      $scope.preloader = true;
      _shop
        .list(o)
        .then(
          (a) => {
            $scope.collection = a.result;
          },
          (a) => {}
        )
        .finally(() => {
          $scope.preloader = false;
        });
    }

    _shop$
      .pipe(filter((item) => item.action === 'buy'))
      .subscribe((item) => {
        if ($scope.collection.find((i) => item.goods.id === i.id)) getCollection(options);
      });

    this.$onChanges = () => {
      options.category = this.category;
      options.count = this.count || 25;

      getCollection(options);

      authorizationSubscription = _system$
        .pipe(filter((m) => m.action === 'login'))
        .subscribe((m) => {
          getCollection(options);
        });
    };

    this.$onDestroy = () => {
      authorizationSubscription.unsubscribe();
    };
  }

  new Controller('shopBox', controller, {
    category: '<',
    count: '<',
  });
})();

(function () {
  [1, 2, 3, 4, 5, 6, 7, 8, 9].forEach((i) => {
    new Batman(`batmanShopBox${i}`, 'shopBox');
  });
})();
