import '../../service/configs/registration.js';
import '../../service/domain/system.js';
import '../../service/helper.js';
import '../../service/domain/popup.js';
import '../../service/registration/registration-deposit.js';
import '../../service/domain/remembered.js'

(function () {
  'use strict';

  const component = {
    name: 'registrationDepositInitForm',
  };

  controller.$inject = ['$scope', 'registration', 'system', 'helper', 'popup', 'remembered', 'registrationDeposit'];

  function controller($scope, _registration, _system, _helper, _popup, _remembered, _registrationDeposit) {
    $scope.preloader = false;
    $scope.currencyList = [];
    $scope.formData = {amount: 0};
    $scope.rememberedLogin = !!_remembered.login;

    let country = {};

    _registrationDeposit
      .getCountries()
      .then((answer) => {
        country = answer.result.filter((item) => item.code === answer.default)[0] || {};
        $scope.currencyList = _registration.prepareCurrencyList(country.currency) || [];
        _registrationDeposit.setFormData({country: country.code});
        $scope.formData.currency = $scope.currencyList[0].key;
        $scope.formData.amount = country.currency[0].defaultDepositAmount;
      })
      .catch((error) => console.error(error))
      .finally(() => ($scope.preloader = false));

    $scope.$watch('formData.currency', (newCurrencyCode) => {
      if (newCurrencyCode !== undefined) {
        country.currency.find((currency) => {
          if (currency.code === newCurrencyCode) {
            $scope.formData.amount = currency.defaultDepositAmount;
          }
        });
      }
    });

    $scope.submit = _helper.form.validator((form) => {
      $scope.preloader = true;
      _registrationDeposit.setFormData($scope.formData);
      _popup.open({name: 'auth-with-deposit'});
      if($scope.rememberedLogin) {
        _registrationDeposit.setCurrentStep('depositStepWithoutBonus')
        return;
      }
      _registrationDeposit.setCurrentStep('depositStep')
    });
  }

  app.component(component.name, {
    controller,
    template: app.getTU(component.name),
  });
})();
