import '../../service/domain/ws.js';
import '../../service/rx/ws$.js';
import '../../service/domain/account.js';
import '../../service/domain/popup.js';
import '../../service/domain/user.js';
import '../../service/domain/state.js';
import '../../service/rx/notification$.js';
import '../../service/rx/system$.js';
import '../../service/domain/bonus.js';
import '../../service/rx/timer$.js';
import '../../service/domain/modal.js';
import '../../service/rx/splash$.js';
import '../../service/helper.js';
import '../../service/configs/promo.js';
import '../../service/configs/country-layout.js';
import '../../service/rx/user$.js';
import '../../service/configs/config.js';
import '../../service/domain/system.js';
import '../../service/analytics/analytics.js';
import '../../service/analytics/heatmap.js';
import '../../service/configs/ws-events.js';
import '../../service/utils/jurisdictions.js';
import '../../service/analytics/p161.js';
import '../../service/domain/achievement.js';
import '../../service/utils/remove-hash-from-url.js';
import '../../service/domain/promocode.js';
import '../../service/domain/remembered.js';
import '../../service/symplify.js';
import '../../service/rx/post-message$.js';
import '../../service/pgw.js';
import '../../service/configs/analytic-events.js';
import '../../service/periodicals/promoHolidayService.js';
import '../../service/mirrorAnalytics.js';
import '../../service/car-promo/car-promo.js';
import '../../service/domain/opt-in.js';
import '../../service/domain/sentry.js';
import '../../service/missed-data.js';
import '../../service/domain/cancelWithdrawal.js';

import { Subject } from 'rxjs';
import { filter, startWith, debounceTime, take, takeUntil } from 'rxjs/operators';

(function () {
  'use strict';

  const component = { name: 'lindaApp' };

  controller.$inject = [
    '$scope',
    '$location',
    '$translate',
    '$state',
    'ws',
    'ws$',
    'account',
    'popup',
    'user',
    'state',
    'notification$',
    'system$',
    'bonus',
    'timer$',
    'modal',
    'splash$',
    'helper',
    'promo.config',
    'countryLayout',
    '$timeout',
    'user$',
    'config',
    'system',
    'analytics',
    'heatmap',
    '$rootScope',
    'wsEvents',
    'jurisdictions',
    'p161',
    'achievement',
    'removeHashFromURL',
    'promocode',
    'remembered',
    'symplify',
    'postMessage$',
    'pgw',
    'analyticsEvents',
    'holidayPromo',
    'mirrorAnalytics',
    '$window',
    'carPromo',
    'optIn',
    'sentry',
    'missedData',
    'cancelWithdrawal'
  ];

  function controller(
    $scope,
    $location,
    $translate,
    $state,
    _ws,
    _ws$,
    _account,
    _popup,
    _user,
    _state,
    _notification$,
    _system$,
    _bonus,
    _timer$,
    _modal,
    _splash$,
    _helper,
    _promo_config,
    _countryLayout,
    $timeout,
    _user$,
    _config,
    _system,
    analytics,
    heatmap,
    $rootScope,
    _wsEvents,
    _jurisdictions,
    _p161,
    _achievement,
    _removeHashFromURL,
    _promocode,
    _remembered,
    _symplify,
    _postMessage$,
    _pgw,
    _analyticsEvents,
    _holidayPromo,
    _mirrorAnalytics,
    $window,
    _carPromo,
    _optIn,
    sentry,
    missedData,
    _cancelWithdrawal,
  ) {
    const destroy$ = new Subject();
    let bonuscode = window.$_GET['bonuscode'];

    $translate.use($state.params.lang);

    _p161.track('view');

    window.$_QA = {
      popup: {
        open(name) {
          $timeout(() => {
            _popup.open({ name });
          });
        },
      },
      modal: {
        open(name, params) {
          $timeout(() => {
            _modal
              .open(name, params)
              .then((a) => {})
              .catch((e) => {});
          });
        },
      },
      state: {
        goto(alias, params) {
          $timeout(() => {
            _state.goto(alias, params);
          });
        },
      },
      socket: {
        ws$(data) {
          _ws$.next(data);
        },
      },
    };

    Object.keys(_promo_config).forEach((promoName) => {
      document.querySelector('body')?.classList.add(`linda-is-${promoName}`);

      switch (promoName) {
        case 'halloween':
          const nflags = (window.localStorage.getItem('promo-notification-flag') || '').split(',');
          const index = nflags.indexOf(promoName);
          if (index < 0) {
            _popup.open({
              name: 'promo-notification',
              content: { name: promoName },
            });
          }
          break;
      }
    });

    _holidayPromo.init();
    _carPromo.init();
    _countryLayout.init();
    _optIn.init();

    _system
      .info()
      .then(({ result }) => {
        _system$.next({ action: 'systemInfo', data: result });
        const FiLangModalWasShown = window.localStorage.getItem('FiLangModalWasShown');
        if (FiLangModalWasShown) {
          return;
        }
        if (result.country.code === 'FI' && $state.params.lang !== 'fi') {
          _modal
            .open('lang-version-notification', result)
            .then(() => {})
            .catch(() => {})
            .finally(() => {
              window.localStorage.setItem('FiLangModalWasShown', 'true');
            });
        }
      })
      .catch(() => {})
      .finally(() => {});

    //        _popup.open({name:'account-status'});

    /*
            _modal.open( 'trustly-registration', {
                profile:{
                    name:'Ulpu',
                    surname:'Koskela',
                    city:'Mustasaari',
                    postcode:'65610',
                    address:'Karkkimalantie 82',
                    gender:'F',
                    birthday:'1970-09-20',
                    email:'',
                    phone:''
                }
            } );
    */

    /*
            _timer$.bufferCount( 30 ).subscribe( x => {
                if( _user.status )
                    _account
                        .info();
            } );
    */

    // -        _ws.construct( { lang: $state.params.lang } );

    _ws$.pipe(filter(({ event, data }) => event === _wsEvents.achieve && data.achieve?.category)).subscribe(
      async ({
        data: {
          achieve: { achievement_id: id },
        },
      }) => {
        const { result: achieve } = await _achievement.item({ id });
        if (achieve.type === 'composite' && achieve.prize !== 0 && _popup.has('composite-achievement')) {
          _popup.open({ name: 'composite-achievement', content: achieve });
          return;
        }

        _popup.open({
          name: `achieve-${achieve.category}`,
          content: achieve,
        });
      },
    );

    // autobonus popup
    // TODO this is kostbllb
    // we dont do bonus filter now
    // we will rewrite it in Q4
    _ws$
      .pipe(
        filter(({ event }) => event === _wsEvents.bonusIssue),
        filter(({ data }) => !data.result[0]?.is_promo_bonus))
      .subscribe((event) => {
        _popup.open({ name: 'autobonus-action', content: event.data.result });
        if (event.data.result[0].status === 'active') {
          _account.info();
        }
      });

    // low balance popup
    _ws$
      .pipe(filter(({ event }) => event === _wsEvents.messageOptimove))
      .subscribe((event) => {
        const result = event.data.replace(/\\n/g, '"').replace(/\\/g, '');
        _popup.open({ name: 'low-balance', content: result });
      });

    const updateLastDepositDetails = () => {
      _account
        .balanceDetails()
        .then(({ result: { last_deposit } }) => {
          _user.balance = { ..._user.balance, last_deposit };
        })
        .catch(() => {});
    };

    const activatePromocode = (promocode) => {
      _promocode
        .activate({ code: promocode })
        .then(() => {
          bonuscode = null;
          window.history.replaceState(null, '', window.location.pathname);
          delete window.$_GET['bonuscode'];
        })
        .catch(() => {});
    };

    if (_user.status) {
      _bonus.list();
      updateLastDepositDetails();
      if (bonuscode) {
        activatePromocode(bonuscode);
      }
    }

    if (!_user.status && bonuscode) {
      const alias = _remembered.login ? 'login' : 'registration';
      _state.goto(alias);
    }

    _system$
      .pipe(filter((message) => message.action === 'login'))
      .subscribe((message) => {
        _bonus.list();
        updateLastDepositDetails();
        if (bonuscode) {
          activatePromocode(bonuscode);
        }

        if (_user.profile.is_new_account === false && _user.profile.sessions_info.is_first_session === false) {
          $timeout(() => {
            _popup.open({ name: 'account-status' });
          });
        }
      });

    _system$.subscribe((message) => {
      switch (message.action) {
        case 'forced logout':
          _account.logout(true).catch(() => {});
          break;
      }
    });

    _ws$
      .pipe(filter(({ event }) => event === _wsEvents.balanceUpdate))
      .subscribe((message) => {
        _user.profile.balance = parseFloat(message.data.balance);
        _user.profile.bonus.casino.sum = parseFloat(message.data.bonus);
        _user.profile.bonus.sport.sum = parseFloat(message.data.bonus_sport);
        _user.profile.pending_withdrawals = message.data.pending_withdrawals;
        _user.profile.coins = message.data.coins || 0;
        _user.profile.tokens = message.data.tokens;
        $scope.$apply();
      });

    // https://jira.gosystem.io/browse/FED-2575
    _ws$
      .pipe(filter(({ event }) => event === _wsEvents.balanceDetails))
      .subscribe(({ data: { last_deposit } }) => {
        _user.balance = { ..._user.balance, last_deposit };

        $scope.$apply();
      });

    _ws$
      .pipe(filter(({ event }) => event === _wsEvents.deposit))
      .subscribe(({ data }) => {
        if (!data || !data.result) {
          return;
        }

        const { amountEUR, isFtd, transactionId } = data.result;

        analytics.send(isFtd ? _analyticsEvents.balanceFTD : _analyticsEvents.balanceDeposit, amountEUR, transactionId);
        heatmap.sendEvent('event', isFtd ? 'ftd' : 'deposit');
        _p161.track('deposit');
      });

    _user$
      .pipe(
        startWith(_user), debounceTime(200))
      .subscribe((user) => {
        if (user.status) {
          heatmap.trigger('heatmap_for_authorized');
        } else {
          heatmap.trigger('heatmap_for_unauthorized');
        }
      });

    _ws$
      .pipe(filter(({ event }) => event === _wsEvents.logout))
      .subscribe((event) => {
        _notification$.next({
          type: 'error',
          code: 0,
          text: event.data.message,
        });
        sentry.sendMessage('logout', {
          level: 'info',
          tags: {
            event: 'logout-ws',
            type: 'success',
            side: 'backend',
            account_id: _user.profile.account_id,
          },
          extra: {
            message: JSON.stringify(event.data),
          }
        });
        _account.logout(true).catch(() => {});
      });

    _ws$
      .pipe(filter(({ event }) => event === _wsEvents.coolOffGameInActivated))
      .subscribe(({ data: { until, info } }) => {
        const coolOffPopupName = 'cool-off';
        const coolOff = {
          active_until: {
            time: until,
          },
          info,
        };

        _user.profile = { ..._user.profile, ...{ cool_off_game_in: coolOff } };

        _popup.open({ name: coolOffPopupName });
      });

    _ws$
      .pipe(filter(({ event }) => event === _wsEvents.coolOffGameInDeactivated))
      .subscribe(() => {
        const coolOffPopupName = 'cool-off';

        _user.profile = { ..._user.profile, ...{ cool_off_game_in: {} } };

        if (_popup.current && _popup.current.includes(coolOffPopupName)) {
          _popup.close();
        }
      });

    window.$_startCoolOff = () =>
      _ws$.next({
        event: _wsEvents.coolOffGameInActivated,
        data: {
          until: Date.now() / 1000 + 10,
          info: {
            win: 100,
            loss: 100,
            bet: 100,
            deposits: 100,
          },
        },
      });

    window.$_endCoolOff = () =>
      _ws$.next({
        event: _wsEvents.coolOffGameInDeactivated,
        data: {},
      });

    // popup by link
    switch ($location.hash()) {
      case 'popup-reg':
        if(!_user.status) {
          _popup.open({ name: 'registration' });
        }
        _removeHashFromURL();
        break;

      case 'popup-login':
        if(!_user.status) {
          _popup.open({ name: 'login' });
        }
        _removeHashFromURL();
        break;

      case 'forgot-password-form':
        if(!_user.status) {
          _popup.open({ name: 'restoration' });
        }
        break;

      case 'deposit':
        if (_user.status) {
          _state.goto('deposit');
        } else {
          _popup.open({
            name: 'login',
            cb() {
              _state.goto('deposit');
            },
          });
        }
        break;
      case 'chat':
        _removeHashFromURL();
        _system$.next({ action: 'open chat' });
        break;
    }

    if ($state.params.restore !== undefined) {
      if (!_user.status) _popup.open({ name: 'new-password' });
      $state.go('.', { restore: undefined }, { notify: false });
    }

    if ($state.params.activation !== undefined) {
      _account.activate({ token: $state.params.activation }).then(
        (answer) => {
          _state.goto('deposit');
        },
        (answer) => {},
      );
      $state.go('.', { activation: undefined }, { notify: false });
    }

    const importSprite = async () => {
      const url = new URL('/dist/_sprite.svg', import.meta.url);
      url.search = '';
      const response = await fetch(url);
      const svgText = await response.text();
      const div = document.createElement('div');
      div.style.display = 'none';
      div.innerHTML = svgText;
      document.body.appendChild(div);
    };

    if ($_CONFIG.useSvgSprite === true) {
      importSprite();
    }

    if ($_CONFIG.insertCdnScripts === false) {
    } else {
      _splash$
        .pipe(
          filter((m) => m === 'loaded'),
          take(1),
          takeUntil(destroy$))
        .subscribe((i) => {
          ['iban'].forEach((item) => {
            _helper.dom.addScript(`/joxi/js/v2/${item}.js`);
          });
        });
    }

    missedData.init();

    // this code may be used for fake WS events
    // _ws$
    //   .next({event: 'deposit', data: {
    //       result: {isFtd: true, paymentName: "PaymentName", transactionId: 0000000},
    //       status: 200
    //     }})

    if ($_CONFIG.symplify) {
      _symplify.init();
    }

    $window.addEventListener('message', (event) => {
      _postMessage$.next(event.data);
    });

    _pgw.init();
    _mirrorAnalytics.sendEventOnSuccessRedirect();
    _cancelWithdrawal.init();

    this.$onDestroy = () => {
      destroy$.next();
      destroy$.unsubscribe();
    };
  }

  app.component(component.name, {
    controller,
    template: app.getTU(component.name),
  });
})();
