export const transcludeResolverEvents = 'transcludeResolverEvents';
import '../domain/game.js';
import '../domain/tournament.js';
import '../../service/domain/promo.js';
import {holidayPromo} from '../periodicals/promoHolidayService.js';

class Events {
  static $inject = ['$q', 'tournament', 'game', 'promo', 'holidayPromo'];

  constructor($q, tournament, game, promo, holidayPromo) {
    this.$q = $q;
    this.game = game;
    this.tournament = tournament;
    this.promo = promo;
    this.holidayPromo = holidayPromo;
  }

  checkOnExist(config) {
    const { alias, params } = config;
    Object.assign(params, { cache: true });

    switch (alias) {
      case 'promo':
        return this.promo.item(params);
      case 'seasonPromoList':
        return this.holidayPromo.getPromoList(params);
      case 'tournament':
        return this.tournament.item(params);
      case 'game':
        return this.game.item(params);
      case 'tournamentActive':
        return (() => {
          const defer = this.$q.defer();
          this.tournament
            .item(params)
            .then((data) => {
              if (data.result.status === 1) {
                defer.resolve(data);
              } else {
                defer.reject(data);
              }
            })
            .catch(() => {
              defer.reject();
            });
          return defer.promise;
        })();
    }
  }
}

app.service(transcludeResolverEvents, Events);
