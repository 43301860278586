(function () {
  'use strict';

  // this directive need to add param "sport: true" to sport routs

  const directive = {name: 'addClassToScroll'};

  controller.$inject = ['$window'];

  function controller($window) {
    function link(scope) {


      setTimeout(() => {
        scrollElem();

        $window.addEventListener('scroll', scrollElem);

        function scrollElem() {
          const scrollpos = document.querySelector('.league-body').offsetTop;
          const el = document.querySelector('.header-logo-wrap');
          const scrollInfo = document.querySelector('.scroll-info');
          let testY = $window.scrollY;

          if(testY >= scrollpos){
            el.classList.add('active');
            scrollInfo.classList.add('is-hide');
            return;
          }
          el.classList.remove('active');
          scrollInfo.classList.remove('is-hide');
        }

        scope.$on('$destroy', () => {
          $window.removeEventListener('scroll', scrollElem);
        });

      }, 0);

    }

    return {
      restrict: 'A',
      link,
    };
  }

  app.directive(directive.name, controller);

})();
