import '../../service/domain/http.js';
import '../../service/domain/user.js';
(function () {
  'use strict';

  controller.$inject = ['$q', 'http', 'user'];

  function controller($q, _http, _user) {
    return {
      list(options) {
        let defer = $q.defer(),
          alias = 'achievementListProductCollections',
          config = {
            params: options,
            cache: false,
            meta: {
              type: 'api',
              alias,
            },
          };

        _http.get(alias, config).then(
          function (answer) {
            defer.resolve(answer.data);
          },
          function (answer) {
            defer.reject(answer.data);
          }
        );

        return defer.promise;
      },

      item(options) {
        let defer = $q.defer(),
          alias = 'achievements',
          config = {
            params: options,
            cache: false,
            meta: {
              type: 'api',
              alias,
            },
          };

        _http.get(alias, config).then(
          function (answer) {
            defer.resolve(answer.data);
          },
          function (answer) {
            defer.reject(answer.data);
          }
        );

        return defer.promise;
      },

      grab(data) {
        const defer = $q.defer(),
          alias = 'achievementTake',
          config = { meta: { alias } };

        _http.post(alias, data, config).then(
          (answer) => {
            _user.profile = answer.data.result;
            defer.resolve(answer.data);
          },
          (answer) => {
            defer.reject(answer.data);
          }
        );

        return defer.promise;
      },

      mask: {
        buy(data) {
          const defer = $q.defer(),
            alias = 'maskCollectionBuy',
            config = {
              meta: { alias },
            };

          _http.post(alias, data, config).then(
            (answer) => {
              defer.resolve(answer.data);
            },
            (answer) => {
              defer.reject(answer.data);
            }
          );

          return defer.promise;
        },
      },
    };
  }

  app.factory('maskCollection', controller);
})();
