import '../../../service/domain/shop.js';
import '../../../service/rx/shop$.js';
import '../../../service/game-page-shop.js';
import { filter } from 'rxjs/operators';

(function () {
  controller.$inject = ['$scope', 'shop', 'shop$', 'gamePageShop'];

  function controller($scope, _shop, _shop$, _gamePageShop) {
    $scope.collection = [];
    $scope.filters = [];
    $scope.activeFilter = null;
    $scope.preloader = false;
    $scope.initialCollection = [];

    const options = {
      category: 'freespin',
    };

    function getCollection(options) {
      $scope.preloader = true;
      return _shop
        .list(options)
        .then(({ result }) => {
          $scope.initialCollection = groupCollectionByGameAlias(result);
          $scope.collection = $scope.initialCollection;
          $scope.filters = getFilters();
        })
        .catch(() => {})
        .finally(() => {
          $scope.preloader = false;
        });
    }

    function getFilters() {
      return $scope.initialCollection.reduce((acc, el) => {
        if (!acc.includes(el?.text)) {
          acc.push(el?.text);
        }
        return acc;
      }, []);
    }
    function groupCollectionByGameAlias(collection) {
      const grouped = {};
      collection
        .filter(({ game }) => game && game.alias)
        .forEach((item) => {
          if (!grouped[item.game.alias]) {
            grouped[item.game.alias] = Object.assign({}, item);
            grouped[item.game.alias].offers = [];
          }
          grouped[item.game.alias].offers.push({
            id: item.id,
            offer: item.offer,
            price: item.price,
          });
        });
      return Object.values(grouped);
    }

    $scope.filterCollection = (filter) => {
      $scope.activeFilter = filter;
      if ($scope.activeFilter !== null) {
        $scope.collection = $scope.initialCollection.filter((item) => item.text === filter);
      }
    };

    $scope.resetFilter = () => {
      $scope.activeFilter = null;
      $scope.collection = $scope.initialCollection;
    };

    _shop$
      .pipe(filter((item) => item.action === 'buy'))
      .subscribe((item) => {
        if ($scope.collection.find((i) => item.goods.id === i.id)) {
          getCollection(options).then(() => {
            $scope.filterCollection($scope.activeFilter);
          });
        }
      });

    const activeTabSubscription = _gamePageShop.tabOpened$.subscribe((tabName) => {
      if (tabName === '') {
         $scope.resetFilter();
      };
    });

    this.$onChanges = () => {
      options.count = this.count || 25;
      if (this.category) options.category = this.category;
      getCollection(options);
    };

    this.$onDestroy = () => {
      this.activeTabSubscription?.unsubscribe();
    };
  }

  new Controller('shopFreespinBox', controller, {
    count: '<',
    category: '<',
  });
})();

(function () {
  [1, 2, 3, 4, 5, 6, 7, 8, 9].forEach((i) => {
    new Batman(`batmanShopFreespinBox${i}`, 'shopFreespinBox');
  });
})();
