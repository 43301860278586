import {transcludeResolver} from '../service/transcludeResolver/transcludeResolver.js';
import '../service/domain/user.js';
import '../service/domain/state.js';

(function () {
  'use strict';

  const directive = { name: 'holidayLink' };

  controller.$inject = ['user', 'state', 'transcludeResolver'];

  function controller(_user, _state, _transcludeResolver) {
    function link(scope, element, attrs, ctrl, transclude) {
      const config = {alias: 'seasonPromoList', params: {category: 'season_promo'}};
      const promoAlias = scope.holidayLink;
      scope.holidayPromo = {};

      _transcludeResolver.get(config).then((data) => {
        if (promoAlias && data.some(promo => promo.alias === promoAlias)) {
          transclude(scope, (clone) => {
            Array.from(clone).forEach((node) => {
              element[0].appendChild(node);
            });
          });
          element[0].onclick = () => {
            if (_user.status) {
              return _state.goto(promoAlias);
            }
            _state.goToAuthorization();
          };

          scope.holidayPromo = data.find((promo) => promo.alias === promoAlias);
        }
      });
    }

    return {
      restrict: 'A',
      scope: {
        holidayLink: '@'
      },
      link,
      transclude: true,
    };
  }

  app.directive(directive.name, controller);
})();
