(function(){

    'use strict';

    // this directive used for watching all inputs in the form and send formData to backabd after blur event

    const directive = { name: 'countDown' };

    controller.$inject = [  ];

    function controller(  ){

        function link( scope, element, attrs ){
            const qualTime = scope.$parent.$eval( attrs[directive.name] ) || false;

            let end = new Date();
            end.setDate(end.getDate()+1);
            if (qualTime) {
              end = new Date(qualTime);
            }

            let now;
            const interval = setInterval(() => {

                    now = Date.now();
                    const timeLeft = parseInt((end.getTime() - now) / 1000);
                    if (timeLeft <= 0) {
                        const timeTotal = parseInt((end.getTime() - now) / 1000);
                        clearInterval(interval);
                        element[0].innerHTML = formatSeconds(timeTotal);
                        return;
                    }
                    element[0].innerHTML = formatSeconds(timeLeft);

                }, 500)

            scope.$on('$destroy', () => {

                clearInterval(interval);

            });
        }

        return {
            restrict: 'A',
            link,
            scope: {
                countUp: "<"
            },
        };
    }

    app.directive( directive.name, controller );


    function formatSeconds(secs) {
        let s = secs % 60;
        let days = Math.floor(secs / 86400);
        secs %= 86400;
        let hours = Math.floor(secs / 3600);
        secs %= 3600;
        let minutes = Math.floor(secs / 60);
        let seconds = secs % 60;

        return `${days > 0 ? `${days}d `:``}${hours > 0 ? `${hours}h `:``}${minutes > 0 ? `${minutes}m `:``}${seconds > 0 && days == 0 && hours == 0 && minutes == 0 ? `${seconds}s `:``}`;
    }

})();
