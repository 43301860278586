const $_PGW = {
  merchantKeyProd: 'dfe120cf-8571-452e-bde0-0e5fd63f61ff',
  merchantKeyDev: 'ca5e6c8f-d48a-4899-b4a1-410a394a1c47',
  enabled: true,
  layoutMode: 'column',
  theme: 'light',
  hideCloseButton: true,
  cssVars: {
    '--pgw-font-family':'"Montserrat", sans-serif',
    '--pgw-background-primary':'#ffffff',
    '--pgw-surface-primary':'#ECC987',
    '--pgw-surface-secondary':'#FFEDCF',
    '--pgw-surface-secondaryHover':'#FFF4C9',
    '--pgw-surface-accent':'#FFFAE6',
    '--pgw-surface-accentHover':'#FFF4C9',
    '--pgw-surface-buttonPrimary':'#3ED70B',
    '--pgw-surface-buttonPrimaryHover':'#32B905',
    '--pgw-surface-buttonPrimaryPress':'#289005',
    '--pgw-surface-buttonPrimaryDisabled':'#9B9B9B',
    '--pgw-surface-buttonSecondary':'#FF7800',
    '--pgw-surface-buttonSecondaryHover':'#EB6F00',
    '--pgw-surface-buttonSecondaryPress':'#BE5900',
    '--pgw-surface-buttonSecondaryDisabled':'#9B9B9B',
    '--pgw-surface-buttonTertiary':'#FFE7BA',
    '--pgw-surface-buttonTertiaryHover':'#F0C981',
    '--pgw-surface-buttonTertiaryPress':'#CCAC70',
    '--pgw-surface-buttonTertiaryDisabled':'#9B9B9B',
    '--pgw-surface-inputPrimary':'#ffffff',
    '--pgw-surface-inputSecondary':'#ffffff',
    '--pgw-surface-inputTertiary':'#F0F2F4',
    '--pgw-surface-tab':'#C98B7A',
    '--pgw-surface-tabHover':'#AB7768',
    '--pgw-surface-tabPress':'#6E3923',
    '--pgw-surface-negative':'#F4463D',
    '--pgw-surface-positiveAlpha':'#64BA621A',
    '--pgw-surface-skeleton':'#F6F7F9',
    '--pgw-text-primary':'#441C0C',
    '--pgw-text-secondary':'#6E3923',
    '--pgw-text-tertiary':'#C5CCD3',
    '--pgw-text-button':'#ffffff',
    '--pgw-text-buttonDisabled':'#F0F2F4',
    '--pgw-text-buttonPrimary':'#ffffff',
    '--pgw-text-buttonPrimaryDisabled':'#f0f0f4',
    '--pgw-text-buttonSecondary':'#ffffff',
    '--pgw-text-buttonSecondaryDisabled':'#f0f0f4',
    '--pgw-text-positive':'#64BA62',
    '--pgw-text-warning':'#FF7800',
    '--pgw-text-negative':'#D01F1F',
    '--pgw-text-constant':'#ffffff',
    '--pgw-icon-primary':'#202A35',
    '--pgw-icon-secondary':'#996D50',
    '--pgw-icon-tertiary':'#C5CCD3',
    '--pgw-icon-button':'#ffffff',
    '--pgw-icon-buttonDisabled':'#F0F2F4',
    '--pgw-icon-buttonPrimary':'#ffffff',
    '--pgw-icon-buttonDisabledPrimary':'#d5e3f3',
    '--pgw-icon-positive':'#64BA62',
    '--pgw-icon-warning':'#FF7800',
    '--pgw-icon-negative':'#F4463D',
    '--pgw-stroke-primary':'#F0F2F4',
    '--pgw-stroke-secondary':'#D8A86B',
    '--pgw-stroke-inputPrimary':'#F0F2F4',
    '--pgw-stroke-inputSecondary':'#E2E5E9',
    '--pgw-stroke-accent':'#E5CAA7',
    '--pgw-stroke-accentHover':'#D8A86B',
    '--pgw-stroke-positive':'#64BA62',
    '--pgw-stroke-positiveAlpha':'#64BA624D',
    '--pgw-stroke-warning':'#FCA209',
    '--pgw-stroke-negative':'#F4463D',
    '--pgw-divider-primary':'#F0F2F4',
    '--pgw-border-radius-s':'4px',
    '--pgw-border-radius-m':'8px',
    '--pgw-border-radius-l':'12px',
    '--pgw-border-radius-xl':'16px',
  },
};
export { $_PGW };
