import '../../../service/domain/popup.js'
import '../../../service/domain/user.js'
import '../../../service/domain/account.js'
(function () {
  controller.$inject = ['$scope', 'popup', 'user', 'account'];

  function controller($scope, _popup, _user, _account) {
    $scope.preloader = false;
    $scope.item = {};

    $scope.buy = (confirm) => {
      if (_user.profile.coins >= $scope.item.price) {
        _popup.open({
          name: confirm,
          content: $scope.item,
        });
        return;
      }
      if (_user.profile.coins === 0 ) {
        _popup.open({ name: 'shop-deposit', content: $scope.item });
        return;
      }
      _popup.open({ name: 'shop-play', content: $scope.item });
    };

    $scope.apply = () => {
      $scope.preloader = true;
      _account
        .changeMask({ mask: $scope.item.offer })
        .then(
          (a) => {},
          (a) => {}
        )
        .catch((e) => {})
        .finally(() => {
          $scope.preloader = false;
        });
    };

    $scope.cancel = () => {
      $scope.preloader = true;
      _account
        .changeMask({ mask: 0 })
        .then(
          (a) => {},
          (a) => {}
        )
        .catch((e) => {})
        .finally(() => {
          $scope.preloader = false;
        });
    };

    this.$onChanges = () => {
      $scope.item = this.item;
    };
  }

  new Controller('shopItem', controller, {
    item: '<',
  });
})();

(function () {
  [1, 2, 3, 4, 5, 6, 7, 8, 9].forEach((i) => {
    new Batman(`batmanShopItem${i}`, 'shopItem');
  });
})();
