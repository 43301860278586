(function () {
  'use strict';

  const component = { name: 'lindaMaskCollectionItem' };

  controller.$inject = ['$scope'];

  function controller($scope) {
    $scope.mask = {};

    this.$onChanges = function () {
      $scope.mask = this.mask;
    };
  }

  app.component(component.name, {
    controller,
    template: app.getTU(component.name),
    bindings: {
      mask: '<',
    },
  });
})();
