import { CommonStore } from './common-store.js';

export class RegistrationStore extends CommonStore {
  constructor() {
    super();
  }

  setFormData(data) {
    let savedFormData = this.getFormData() || {};
    const updatedData = Object.assign(savedFormData, data);
    this.setData({formData: updatedData});
  }

  getFormData() {
    return this.getData('formData');
  }
}
