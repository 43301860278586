import '../../service/configs/registration.js';
import '../../service/helper.js';
import '../../service/registration/registration-deposit.js';
import '../../service/domain/popup.js';

(function () {
  'use strict';

  const component = {
    name: 'registrationDepositForm',
  };

  controller.$inject = ['$scope', 'registration', 'helper', 'registrationDeposit', 'popup', '$timeout'];

  function controller($scope, _registration, _helper, _registrationDeposit, _popup, $timeout) {
    $scope.preloader = false;
    $scope.currencyList = [];
    $scope.countries = [];
    $scope.country = {};
    $scope.formData = {
      gender: 'M',
      accept: true,
      gdpr_marketing: true,
    };
    $scope.registrationStep = 0;
    $scope.formData.currency = _registrationDeposit.store.getFormData()?.currency;

    const vm = this;
    let firstSubmit = true;
    let formHasOwnRetype = false;
    const subscription = _registrationDeposit.stepUpdate$.subscribe((answer) => {
      $scope.currentStep = answer;
    });

    vm.$onInit = () => {
      _registrationDeposit.getAvatars().then(({ result }) => {
        $scope.avatars = result;
      });

      _registrationDeposit.getCountries().then((answer) => {
        $scope.countries = answer.result;
        if (!$scope.formData.country) {
          $scope.formData.country = answer.default;
        }
      });
    };

    $scope.setData = (data) => {
      _registrationDeposit.setFormData(data);
    };
    $scope.setStep = (step) => {
      _registrationDeposit.setCurrentStep(step);
    };
    $scope.setRegistrationStep = (step) => {
      $scope.registrationStep = step;
    };
    $scope.$watch('formData.country', (newVal, oldVal) => {
      if (newVal) {
        $scope.country = $scope.countries.filter((item) => item.code === newVal)[0] || {};
        $scope.currencyList = _registration.prepareCurrencyList($scope.country.currency) || [];
        if (newVal !== oldVal) {
          $scope.formData.currency = $scope.currencyList[0].key;
        }

        if (!$scope.formData.phone || $scope.formData.phone.length <= 6) {
          $scope.formData.phone = `+${parseInt($scope.country.prefix.replace(/\-/g, ''), 10)}`;
        }
      }
    });

    const setRetype = () => {
      if (!$scope.formData.hasOwnProperty('retype')) {
        $scope.formData.retype = $scope.formData.password;
        return;
      }
      formHasOwnRetype = true;
    };

    $scope.nextPage = _helper.form.validator(() => {
      if (firstSubmit === true && $scope.formData.gdpr_marketing === false) {
        _registrationDeposit.openGdprModalIfNeeded($scope.formData.gdpr_marketing).then(() => {
          $scope.formData.gdpr_marketing = true;
        });
      }
      firstSubmit = false;
    });

    $scope.lastPage = _helper.form.validator(() => {
      $scope.preloader = true;
      setRetype();

      _registrationDeposit
        .submitForm($scope.formData)
        .then(() => {})
        .catch(() => {
          $scope.setRegistrationStep(1);
        })
        .finally(() => {
          queueMicrotask(() => {
            $scope.preloader = false;
          });
        });
    });

    vm.$onDestroy = () => {
      subscription.unsubscribe();
    };
  }

  app.component(component.name, {
    controller,
    template: app.getTU(component.name),
  });
})();
