import '../../service/domain/paysystem.js';
import '../../service/registration/registration-deposit.js';
import '../../service/domain/remembered.js';

(function () {
  'use strict';

  const component = {
    name: 'registrationDepositPaysystems',
  };

  controller.$inject = ['$scope', 'paysystem', 'registrationDeposit', 'remembered'];

  function controller($scope, _paysystem, _registrationDeposit, _remembered) {
    $scope.paymentList = [];
    $scope.preloader = true;

    const authBufferData = _registrationDeposit.store.getFormData();
    const subscription = _registrationDeposit.stepUpdate$.subscribe((answer) => {
      $scope.currentStep = answer;
    });

    const config = {
      currency: authBufferData.currency,
      country: authBufferData.country,
      type: 'deposit',
    };

    _registrationDeposit
      .getBankingList(config)
      .then((result) => {
        $scope.paymentList = result.map((item) => Object.assign(item, { purpose: config.type }));
      })
      .finally(() => {
        $scope.preloader = false;
      });

    $scope.setStep = (step) => {
      _registrationDeposit.setCurrentStep(step);
    };
    $scope.choosePaysystem = (paysystem) => {
      _registrationDeposit.store.setFormData({ paysystem: paysystem });
      if (_remembered.login) {
        _registrationDeposit.setCurrentStep('loginStep');
        return;
      }
      _registrationDeposit.setCurrentStep('registrationStep');
    };

    this.$onDestroy = () => {
      subscription.unsubscribe();
    };
  }

  app.component(component.name, {
    controller,
    template: app.getTU(component.name),
  });
})();
