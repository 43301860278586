import '../../../service/gamification/mask-collection.js';
import '../../../service/rx/shop$.js';
import { filter } from 'rxjs/operators';
(function () {
  'use strict';

  const component = { name: 'lindaMaskCollectionBox' };

  controller.$inject = ['$scope', '$timeout', 'maskCollection', 'shop$'];
  function controller($scope, $timeout, _maskCollection, _shop$) {
    const vm = this;

    $scope.masks = [];
    $scope.collection = {};
    $scope.preloader = false;

    const subscription = _shop$
      .pipe(
        filter(({ action }) => action === 'buy'),
        filter(({ goods: shopItem }) =>
          $scope.masks.some((mask) => mask.shop_item && mask.shop_item.id === shopItem.id),
        ),
        filter(({ goods: shopItem }) => {
          $scope.preloader = true;
          changeStatusMaskAfterBuy(shopItem);
        }),
      )
      .subscribe();

    $scope.grab = () => {
      $scope.preloader = true;

      _maskCollection
        .grab({ id: $scope.collection.id })
        .then(() => {
          $scope.collection.status = 3;
        })
        .catch(() => {})
        .finally(() => {
          $scope.preloader = false;
        });
    };

    vm.$onChanges = () => getCollection();

    vm.$onDestroy = () => subscription.unsubscribe();

    function changeStatusMaskAfterBuy(buyedMask) {
      $scope.masks.forEach((mask) => {
        if (mask.shop_item && mask.shop_item.id === buyedMask.id) mask.status = 2;
      });

      $scope.collection.progress[0] += 1;
      $scope.collection.progress[2] = ($scope.collection.progress[0] / $scope.collection.progress[1]) * 100;

      if ($scope.collection.progress[2] === 100) {
        $timeout(() => {
          getCollection();
          $scope.collection.status = 2;
        }, 1000);
      }

      $scope.preloader = false;
    }

    function getCollection() {
      $scope.preloader = true;

      const options = {
        category: vm.name || '',
      };

      _maskCollection
        .item(options)
        .then((answer) => {
          $scope.masks = answer.result.filter((mask) => mask.type !== 'composit_collection');
          $scope.collection = answer.result[0] || {};
        })
        .catch((error) => {})
        .finally(() => {
          $scope.preloader = false;
        });
    }
  }

  app.component(component.name, {
    controller,
    template: app.getTU(component.name),
    bindings: {
      name: '<',
    },
  });
})();
