import '../../service/domain/http.js';
import '../../service/domain/user.js';
import '../../service/domain/bonus.js';
(function () {
  app.factory('shop', controller);
  controller.$inject = ['http', '$q', 'user', 'bonus'];

  function controller(_http, $q, _user, _bonus) {
    return {
      buy(data) {
        const defer = $q.defer();
        const alias = 'shopBuy';
        const config = {
          meta: { alias },
        };

        _http.post(alias, data, config).then(
          (answer) => {
            _user.profile = answer.data.result;
            _bonus.list();
            defer.resolve(answer);
          },
          (answer) => {
            defer.reject(answer);
          },
        );

        return defer.promise;
      },

      list(options) {
        const defer = $q.defer();
        const alias = 'shopItems';
        const config = {
          params: options,
          cache: false,
          meta: {
            type: 'api',
            alias,
          },
        };
        _http.get(alias, config).then(
          (a) => {
            defer.resolve(a.data);
          },
          (a) => {
            defer.reject(a.data);
          },
        );
        return defer.promise;
      },
    };
  }
})();
