(function () {

    'use strict';

    const directive = { name: 'wazambaFirstScreenScroll' };

    controller.$inject = [ '$state' ];

    function controller( $state ){

        function link( scope, element, attrs ){

            const _body = document.body;
            const screenHeight =  window.innerHeight;

            detectScrollPosition();

            window.addEventListener( 'scroll', detectScrollPosition );

            function detectScrollPosition(){
                let scrollPos = document.scrollingElement.scrollTop;

                _body.classList.toggle('is-first-screen',scrollPos <= screenHeight );
            }

            scope.$on( '$destroy', () => {
                window.removeEventListener( 'scroll', detectScrollPosition );
            } );
        }

        return {
            restrict: 'A',
            link
        };
    }

    app.directive( directive.name, controller );

})();