import '../../service/helper.js';
import '../../service/localStorage.js';
import '../../service/domain/account.js';
import '../../service/registration/registration-deposit.js';
import '../../service/domain/paymentWindow.js';
import '../../service/domain/popup.js';

(function () {
  'use strict';

  const component = {
    name: 'registrationDepositLogin',
  };

  controller.$inject = ['$scope', '$timeout', 'helper', 'localStorage', 'registrationDeposit', 'account', 'paymentWindow', 'popup'];

  function controller($scope, $timeout, _helper, _localStorage, _registrationDeposit, _account, _paymentWindow, _popup) {
    $scope.rememberedLogin = _localStorage.get('rememberedLogin') || false;
    $scope.rememberedAvatar = _localStorage.get('rememberedAvatar') || '';
    $scope.rememberedName = _localStorage.get('rememberedName') || '';
    $scope.preloader = false;

    $scope.setData = (data) => {
      _registrationDeposit.setFormData(data);
    }
    $scope.setStep = (step) => {
      _registrationDeposit.setCurrentStep(step)
    }

    $scope.credentials = {
      login: $scope.rememberedLogin === false ? undefined : $scope.rememberedLogin,
    };

    $scope.resetLoginCredentials = () => (($scope.credentials.login = ''), ($scope.rememberedLogin = false));

    $scope.submit = _helper.form.validator(() => {
      $scope.preloader = true;
      const depositWindow = _paymentWindow.prepared();
      _account
        .login($scope.credentials)
        .then(({result}) => {
          _registrationDeposit.checkDepositScenario(result, depositWindow);
        })
        .catch((e) => {
          depositWindow.close()
        })
        .finally(() => {
          $scope.preloader = false
        })
    });
  }

  app.component(component.name, {
    controller,
    template: app.getTU(component.name),
  });
})();
