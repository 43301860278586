(function () {

  'use strict';

  // this directive need to add param "sport: true" to sport routs

  const directive = {name: 'backTo'};

  controller.$inject = ['state', 'config', '$state', '$rootScope', 'user'];

  function controller(_state, _config, $state, $rootScope, _user) {

    const link = (scope, element, attrs) => {

      function go() {

        if (_user.status && _state.history.length > 1) {
          _state.goto('topCategory', { name: 'top' });
        } else {
          window.history.back();
        }
      }

      element[0].addEventListener('click',go )
    };

    return {
      restrict: 'A',
      link
    };
  }

  app.directive(directive.name, controller);

})();
