(function () {
  'use strict';

  app.filter('fontClassFromCurrency', function () {
    return function (currency) {
      if (
        ['INR', 'HUF', 'CZK', 'PHP', 'MYR', 'ZAR', 'TRY', 'THB', 'JPY', 'PEN', 'BRL', 'PLN', 'NOK'].includes(currency)
      ) {
        return 'medium';
      }
      if (['KRW', 'VND', 'IDR', 'NGN', 'CLP'].includes(currency)) {
        return 'small';
      }
      return 'default';
    };
  });
})();
