import '../../../service/domain/user.js';
import '../../../service/rx/user$.js';
import '../../../service/rx/system$.js';
import {filter} from 'rxjs/operators';
(function () {
  'use strict';

  const component = { name: 'lindaRegistrationPage' };

  controller.$inject = ['$scope', '$state', 'user', 'user$', 'system$'];

  function controller($scope, $state, _user, _user$, _system$) {
    let redirect = '';

    this.$onChanges = function () {
      redirect = this.redirect;

      if (_user.status && redirect) $state.go(redirect);
    };

    let subscription = _system$
      .pipe(filter((m) => m.action === 'statusChanged'))
      .subscribe(() => {
        if (_user.status && redirect) $state.go(redirect);
      });
  }

  app.component(component.name, {
    controller,
    template: app.getTU(component.name),
    bindings: {
      redirect: '<',
    },
  });
})();
