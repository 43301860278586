(function () {
  'use strict';

  const directive = { name: 'wazambaScrollIntoView' };

  controller.$inject = ['$timeout'];

  function controller($timeout) {
    function link(scope, element, attrs) {
      const config = scope.$eval(attrs[directive.name]);
      const scrollIntoViewOptions = config.scrollIntoViewOptions || { behavior: 'smooth' };


        scope.scroll = (isScrollNeeded) => {
          $timeout(() => {
            if (!isScrollNeeded) return;
            const scrollToElementName = document.querySelector(config.element);
            if (!scrollToElementName) return;
            scrollToElementName.scrollIntoView(scrollIntoViewOptions);
          }, 100);
        };
    }

    return {
      restrict: 'A',
      link: link,
    };
  }

  app.directive(directive.name, controller);
})();
