(function(){
    'use strict';

    const directive = { name: 'timestamp' };

    controller.$inject = [ ];

    function controller(  ){


        function random(max){
            return Math.floor((Math.random() * max));
        }



        function link( scope, element, attrs ){
            scope.timestamp = random(1000)
        }
        return {
            restrict: 'A',
            link
        };
    }
    app.directive( directive.name, controller );
})();
