(function () {

  'use strict';

  // this directive need to add param "sport: true" to sport routs

  const directive = {name: 'wazambaHideSidebar'};

  controller.$inject = [];

  function controller() {

    function link(scope) {

      const bodyClass = 'linda-hide-sidebar';

      document.body.classList.add(bodyClass);

      scope.$on('$destroy', () => {
        document.body.classList.remove(bodyClass);
      });

    }

    return {
      restrict: 'A',
      link
    };
  }

  app.directive(directive.name, controller);

})();
