import '../../service/domain/tournament.js';
(function () {
  'use strict';

  const component = { name: 'lindaTournamentSmallWidget' };

  controller.$inject = ['$scope', 'tournament', '$state'];

  function controller($scope, _tournament, $state) {
    $scope.tournament = {};
    $scope.preloader = false;
    const options = {
      count: 1,
    };

    $scope.href = $state.href;

    function getCollection(opt) {
      $scope.preloader = true;
      _tournament.list(opt).then(
        (answer) => {
          $scope.tournament = answer.result[0];
          $scope.preloader = false;
        },
        (answer) => {
          $scope.preloader = false;
        }
      );
    }

    this.$onChanges = () => {
      options.category = this.category;
      getCollection(options);
    };
  }

  app.component(component.name, {
    controller: controller,
    template: app.getTU(component.name),
    bindings: {
      category: '<',
    },
  });
})();
