import '../../../service/domain/content-buffer.js'
import '../../../service/rx/shop$.js'
import '../../../service/domain/shop.js'
import '../../../service/domain/popup.js'
(function () {
  controller.$inject = ['$scope', 'contentBuffer', 'shop$', 'shop', 'popup'];

  function controller($scope, _contentBuffer, _shop$, _shop, _popup) {
    $scope.item = _contentBuffer.read('popup');
    $scope.preloader = false;

    $scope.buy = (activateAfterBuy) => {
      $scope.preloader = true;
      let config = { id: $scope.item.id };
      if (activateAfterBuy) {
        Object.assign(config, { activateAfterBuy });
      }
      _shop
        .buy(config)
        .then(
          (a) => {
            _shop$.next({ action: 'buy', goods: $scope.item });
            _popup.close();
          },
          (a) => {}
        )
        .finally(() => {
          $scope.preloader = false;
        });
    };
  }

  new Controller('shopConfirmationPopup', controller);
})();

(function () {
  [1, 2, 3, 4, 5, 6, 7, 8, 9].forEach((i) => {
    new Batman(`batmanShopConfirmationPopup${i}`, 'shopConfirmationPopup');
  });
})();
