export class CommonStore {
  constructor() {
    this.store = {};
  }

  setData(data) {
    Object.assign(this.store, data);
  };

  getData(key) {
    return this.store[key];
  };

  removeData(key) {
    delete this.store[key];
  };
}
