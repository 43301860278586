import '../../../service/domain/account.js';
(function () {
  'use strict';

  const component = { name: 'lindaProfileWin' };

  controller.$inject = ['$scope', 'account'];

  function controller($scope, _account) {
    $scope.collection = [];

    this.$onInit = () => {
      const o = {
        count: this.count || 4,
      };

      $scope.preloader = true;
      _account.win(o).then(
        (answer) => {
          $scope.collection = answer.result;
          $scope.preloader = false;
        },
        (answer) => {
          $scope.preloader = false;
        }
      );
    };
  }

  app.component(component.name, {
    controller,
    template: app.getTU(component.name),
    bindings: {
      count: '<',
    },
  });
})();
