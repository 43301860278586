import {state} from '../domain/state.js';
import {account} from '../domain/account.js';
import {promo} from '../domain/promo.js'
import {modal} from '../domain/modal.js';
import {system} from '../domain/system.js';
import {registration} from '../configs/registration.js';
import {RegistrationStore} from '../../classes/store/registration-store.js';
import {BehaviorSubject} from 'rxjs';

export const commonRegistration = 'commonRegistration';

class Registration {
  static $inject = ['state', 'account', 'system', 'registration', 'promo', 'modal'];

  step = '';
  store = new RegistrationStore();
  stepUpdate$ = new BehaviorSubject(this.step);

  constructor(state, account, system, registration, promo, modal) {
    this.state = state;
    this.account = account;
    this.system = system;
    this.registration = registration;
    this.promo = promo;
    this.modal = modal;
  }
  resetSteps() {
    this.step = '';
    this.stepUpdate$.next(this.step);
  }

  setCurrentStep(step) {
    this.step = step;
    this.stepUpdate$.next(this.step);
  };

  getPromos() {
    if (this.store.getData('promotions')) {
      return Promise.resolve(this.store.getData('promotions'));
    }
    return this.promo.list({category: 'welcome_bonus'})
      .then(({result}) => {
        this.store.setData({promotions: result});
        return this.store.getData('promotions');
      })
  }

  getCountries() {
    if (this.store.getData('countries')) {
      return new Promise(resolve(this.store.getData('countries')))
    }
    return this.system.countries()
      .then((answer) => answer);
  }

  getAvatars() {
    if (this.store.getData('avatars')) {
      return new Promise(resolve(this.store.getData('avatars')))
    }
    return this.system.avatars()
      .then((answer) => answer);
  }

  setBonus(data) {
    this.store.setFormData({bonus: data});
  }

  setFormData(data) {
    this.store.setFormData(data);
  }

  openGdprModalIfNeeded(gdpr) {
    if (!gdpr) {
      return this.modal.open('gdpr-registration');
    }
  }

  submit(validationFields) {
    return this.account.register(validationFields)
      .then(({result: {login, prefix}}) => {
        if (login) {
          this.registration.marketing(login, prefix);
        }
      })
  }
}

app.service(commonRegistration, Registration)
