import '../../../service/domain/content-buffer.js';
import '../../../service/domain/payment.js';
import '../../../service/domain/modal.js';
import { interval } from 'rxjs';
import { filter } from 'rxjs/operators';

(function () {
  'use strict';

  // this popup will be work if iframe with name in frame exit

  const component = { name: 'lindaQuickDepositThreedsModal' };

  controller.$inject = ['$scope', 'contentBuffer', '$element', 'payment', 'modal'];

  function controller($scope, _contentBuffer, $element, _payment, _modal) {
    $scope.threeDs = _contentBuffer.read('modal').redirectOutput;
    $scope.transactionId = _contentBuffer.read('modal').transactionId;

    const shadowForm = document.createElement('form');
    const iframe = $element[0].querySelector('iframe');
    iframe.style.cssText = `
            width: ${$scope.threeDs.width + $scope.threeDs.sizeUnit};
            height: ${$scope.threeDs.height + $scope.threeDs.sizeUnit}`;

    let interval$;

    if (iframe && iframe.name) {
      shadowForm.name = '3ds-form';
      shadowForm.method = $scope.threeDs.method;
      shadowForm.action = $scope.threeDs.url;
      shadowForm.target = iframe.name;

      Object.keys($scope.threeDs.parameters).forEach((item) => {
        const shadowInput = document.createElement('input');
        shadowInput.type = 'hidden';
        shadowInput.name = item;
        shadowInput.value = $scope.threeDs.parameters[item];
        shadowForm.appendChild(shadowInput);
      });

      document.body.appendChild(shadowForm);

      shadowForm.submit();

      interval$ = interval(3000)
        .pipe(filter(() => flag))
        .subscribe(() => {
          check();
        });

      let flag = true;

      function check() {
        flag = false;
        _payment
          .check({ alias: $scope.transactionId, safeRequest: true })
          .then((a) => {
            switch (a.result.status) {
              case 'pending':
              case 'processing':
                flag = true;
                break;

              case 'complete':
                interval$.unsubscribe();
                _modal.answer = true;
                break;

              case 'cancel':
              case 'cancel_error':
              case 'decline':
              case 'error':

              default:
                interval$.unsubscribe();
                _modal.answer = false;
                break;
            }
          })
          .catch(() => {
            interval$.unsubscribe();
          });
      }
    } else {
      console.error('=====  iframe with name not found  =====');
    }

    this.$onDestroy = () => {
      shadowForm.remove();
      interval$.unsubscribe();
    };
  }

  app.component(component.name, {
    controller: controller,
    template: app.getTU(component.name),
  });
})();
