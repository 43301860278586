import '../../../service/domain/game.js'
(function () {
  const classLib = {
    full: 'linda-has-games', // клас з'являється на блоці, коли елементи є у collection
    empty: 'linda-not-found', // коли результат пошуку пустий
  };

  controller.$inject = ['$scope', 'game', '$element', '$timeout'];

  function controller($scope, _game, $element, $timeout) {
    $scope.collection = [];
    $scope.preloader = false;
    $scope.searchBar = '';
    $scope.$watch('searchBar', (newVal, oldVal) => {
      if (newVal.length >= 2) {
        $scope.preloader = true;
        const options = {
          search: newVal,
          count: 6,
        };
        _game
          .collection(options)
          .then((answer) => {
            $scope.collection = answer.result;
            $element[0].classList.toggle(classLib.full, answer.result.length > 0);
            $element[0].classList.toggle(classLib.empty, answer.result.length === 0);
          })
          .finally(() => {
            $scope.preloader = false;
          });
      } else {
        $timeout(() => {
          $element[0].classList.remove(classLib.full);
          $element[0].classList.remove(classLib.empty);
          $scope.collection = [];
        }, 0);
      }
    });
  }

  new Controller('searchBox', controller);
})();

(function () {
  [1, 2, 3, 4, 5, 6, 7, 8, 9].forEach((i) => {
    new Batman(`batmanSearchBox${i}`, 'searchBox');
  });
})();
