import '../../../service/helper.js';
(function () {
  'use strict';

  const component = {
    name: 'lindaShopPage',
  };

  controller.$inject = ['$scope', 'helper'];

  function controller($scope, _helper) {
    $scope.preloader = false;
  }

  app.component(component.name, {
    controller,
    template: app.getTU(component.name),
  });
})();
