import '../../service/configs/promo.js';
import '../../service/domain/content-buffer.js';
(function () {
  'use strict';

  const component = { name: 'lindaPromoNotificationPopup' };

  controller.$inject = ['$scope', '$window', 'promo.config', 'contentBuffer'];

  function controller($scope, $window, _promo_config, _contentBuffer) {
    const promo = _contentBuffer.read('popup');

    this.$onDestroy = () => {
      const nflags = ($window.localStorage.getItem('promo-notification-flag') || '').split(',');

      nflags.push(promo.name);

      const result = nflags.filter((item) => {
        return item !== '';
      });

      $window.localStorage.setItem('promo-notification-flag', result.join(','));
    };
  }

  app.component(component.name, {
    controller,
    template: app.getTU(component.name),
  });
})();
