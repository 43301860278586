(function () {
  'use strict';

  const component = { name: 'lindaMaskCollectionPage' };

  controller.$inject = ['$scope', '$state'];

  function controller($scope, $state) {
    $scope.name = $state.params.name;
  }

  app.component(component.name, {
    controller,
    template: app.getTU(component.name),
  });
})();
