(function () {
  'use strict';
  const directive = {name: 'wazambaCheckTournamentsIsEmpty'};
  function controller() {
    function link(scope) {
      scope.$watch('collection', (newVal, oldVal) => {
        scope.isEpmtyActiveList = scope.collection.filter(tournament => tournament.status < 2).length === 0;
        scope.isEpmtyFinishedList = scope.collection.filter(tournament => tournament.status === 2).length === 0;
      })
    }

    return {
      restrict: 'A',
      link
    };
  }

  app.directive(directive.name, controller);
})();
